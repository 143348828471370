import React, { useState, useEffect } from "react";
import { getDoc, doc, getFirestore } from "@firebase/firestore";
import {
  Row,
  Col,
  Table,
  Card,
  Input,
  Tag,
  Typography,
  Select,
  Button,
} from "antd";
import { useQuery } from "@apollo/client";
import {
  get_order_variables,
  get_orders,
  get_system_data,
  getSystemDataVariable2,
} from "../../gql/gql_query";
import DateRange from "../../components/date_range";
// import { useToasts } from 'react-toast-notifications';
import { useNavigate } from "react-router-dom";
import { AuthState, Order, PayPointSystem } from "../../store";
import withUser from "../../hocs/with_user";
import { subDays, format } from "date-fns";
import LoadingSpinner from "../../components/loading_spinner";
import ExportButton from "../../components/export_button";
import { ExportExcel } from "../../services/xlsx_service";
import PdfExportButton from "../../components/pdf_export_button";
import ButtonWithIcon from "../../components/button_with_icon";
import DeliveryInfo from "../../components/delivery_info";
import { generateInvoice } from "../../helpers/invoice_helper";
import { formatMoney } from "../../helpers/util";
import { paths } from "../../routes/paths";
import { Link } from "react-router-dom";
import { availableCRM } from "../../helpers/crm_helper";
//import { availableDelivery } from '../../helpers/delivery_helper'
import DeeDelivery from "../../components/dee_delivery";
import ButtonGroup from "antd/lib/button/button-group";
import withMerchant from "../../hocs/with_merchant";
import PayPoint from "../../components/order_paypoint";
import { EyeOutlined } from "@ant-design/icons";
import { DelvieryIcon, EyeIcon } from "../../assets/icons/svg_icons";
import ArlupostDelivery from "../../components/arlupost_delivery";
const { Search } = Input;
const { Option } = Select;
interface P {
  user: AuthState;
  merchant: any;
}
type Filter = "orderNo" | "phone" | "chanel" | "status" | "customer";
type ExcelFormat = "summary" | "record";
const systemdata_codes: string[] = [
  "invoice_logo",
  "invoice_note",
  "ninjavan_integration_status",
  "deedelivery_integration_status",
  "arlupost_integration_status",
  "royalexpress_integration_status",
];
const OrderList = (props: P) => {
  const navigate = useNavigate();
  const [searchText, setSearchText] = useState(null as string | null);
  const [exportLoading, setExportLoading] = useState(null as boolean | null);
  const [excelFormat, setExcelFormat] = useState("record" as ExcelFormat);
  const [merchantData, setMerchantData] = useState(null as any | null);
  const [ranges, setRanges] = useState({
    startDate: subDays(new Date(), 30),
    endDate: new Date(),
  });
  const [selectedFilter, setSelectedFilter] = useState("orderNo" as Filter);
  const merchantId =
    props.user.status === "loggedIn" ? props.user.userInfo?.merchantId! : null;
  const { loading, error, data, refetch } = useQuery(get_orders, {
    variables: get_order_variables(
      merchantId || "",
      ranges.startDate,
      ranges.endDate
    ),
    fetchPolicy: "network-only",
  });

  const orderData = data?.orders;
  const shipmentData = orderData?.filter(
    (item: any) => item.shipment?.shipment_agent === "ARLUPOST"
  );
  console.log(shipmentData, "order data");

  const {
    loading: sysLoading,
    error: sysError,
    data: sysData,
  } = useQuery(get_system_data, {
    variables: getSystemDataVariable2(merchantId || "", systemdata_codes),
  });
  useEffect(() => {
    if (merchantId) {
      const ref = doc(getFirestore(), `merchants/${merchantId}`);
      getDoc(ref).then((doc) => {
        if (doc.exists()) {
          setMerchantData(doc.data());
        }
      });
    }
  }, [merchantId]);
  // useEffect(() => {
  //     const mins = 3 * 60 * 1000 // 1000*60*3 (1000 miliseconds, times 60 seconds, times 3 minutes)
  //     const timer = setInterval(() => {
  //         refetch().then((res: any) => console.log('pulled'))
  //     }, mins);
  //     return () => clearInterval(timer);
  // }, [refetch])
  const onSelect = async (startDate: Date, endDate: Date) => {
    // console.log('start', startDate, 'end', endDate)
    setRanges({ startDate, endDate });
    //await refetch();
  };
  const availableDeli = (agent: string) => {
    if (sysData) {
      const sd = sysData.findManySystemData.find(
        (sd: any) =>
          sd.code === `${agent.toLocaleLowerCase()}_integration_status`
      );
      console.log(sd, "system data");

      if (sd && sd.metadata === "active") return true;
    }
    return false;
  };
  const getInvoiceInfo = () => {
    let invoiceLogo: any = null,
      invoiceNote: any = null;
    if (sysData) {
      const lsd = sysData.findManySystemData.find(
        (sd: any) => sd.code === "invoice_logo"
      );
      if (lsd) invoiceLogo = lsd.metadata;
      const nsd = sysData.findManySystemData.find(
        (sd: any) => sd.code === "invoice_note"
      );
      if (nsd) invoiceNote = nsd.metadata;
    }
    return { invoiceLogo, invoiceNote };
  };
  const onExport = () => {
    setExportLoading(true);
    const fileName =
      "orders_" +
      format(ranges.startDate, "yyyy-MMM-dd") +
      "_" +
      format(ranges.endDate, "yyyy-MMM-dd");
    let excelData: any[] = [];
    (data && data.orders ? data.orders : []).map((o: Order) => {
      const orderNo = o.order_number;
      const date = format(new Date(o.created_at), "yyyy-MMM-dd");
      const customer =
        o.merchant_customer && o.merchant_customer.customer
          ? o.merchant_customer.customer.name
          : "";
      const customerNote = o.customer_note;
      const phone =
        o.merchant_customer && o.merchant_customer.customer
          ? o.merchant_customer.customer.contact_number
          : "";
      const saleChannel = o.sale_channel;
      const branch = o.branch?.name || "default";
      const orderStatus = o.order_status;
      const payment = o.payment_method;
      const paymentStatus = o.payment_info;
      //  const totalAmount = o.total_price;
      const discount = o.discount;
      const couponCode = o.cupon_code;
      const couponAmount = o.cupon_discount || 0;

      const givePoint = o.point_gain || 0;
      const usedPoint = o.point_used || 0;
      const usedPointAmount = o.point_discount || 0;
      const tax = o.tax;
      const total = o.grand_total;
      const soldBy = o.cashier_name || "";
      const shippingAddress = o.shipping_fee_name || o.shipping_address || "";
      const shippingAmount = o.shipping_fee_price || "";
      let perItemDiscount = 0;
      o.order_items.forEach((oi, index) => {
        let itemQty = oi.count,
          itemName = oi.stock_item ? oi.stock_item.name : "";
        perItemDiscount =
          ((oi.price_b4_discount || 0) - (oi.price || 0)) / oi.count;
        if (index === 0) {
          excelData.push({
            orderNo,
            date,
            customer,
            phone,
            customerNote,
            saleChannel,
            orderStatus,
            branch,
            payment,
            paymentStatus,
            couponCode,
            couponAmount,
            discount,
            tax,
            shippingAmount,
            total,
            usedPoint,
            usedPointAmount,
            givePoint,
            shippingAddress,
            soldBy,
            itemQty,
            perItemDiscount,
            itemName,
          });
        } else {
          excelData.push({
            orderNo,
            date: "",
            customer: "",
            phone: "",
            customerNote: "",
            saleChannel: "",
            orderStatus: "",
            branch: "",
            payment: "",
            paymentStatus: "",
            couponCode: "",
            couponAmount: "",
            discount: "",
            tax: "",
            shippingAmount: "",
            total: "",
            usedPoint: "",
            usedPointAmount: "",
            givePoint: "",
            shippingAddress: "",
            soldBy: "",
            itemQty,
            perItemDiscount,
            itemName,
          });
        }
      });
    });
    ExportExcel(excelData, fileName);
    setExportLoading(false);
  };
  const onSummaryExport = () => {
    setExportLoading(true);
    const fileName =
      "summary_orders_" +
      format(ranges.startDate, "yyyy-MMM-dd") +
      "_" +
      format(ranges.endDate, "yyyy-MMM-dd");
    let excelData: any[] = [];
    (data && data.orders ? data.orders : []).map((o: Order) => {
      const orderNo = o.order_number;
      const orderDate = format(new Date(o.created_at), "yyyy-MMM-dd");
      const customerName =
        o.merchant_customer && o.merchant_customer.customer
          ? o.merchant_customer.customer.name
          : "";
      const customerNote = o.customer_note;
      const customerPhone =
        o.merchant_customer && o.merchant_customer.customer
          ? o.merchant_customer.customer.contact_number
          : "";
      const saleChannel = o.sale_channel;
      const branch = o.branch?.name || "default";
      const orderStatus = o.order_status;
      const payment = o.payment_method;
      const paymentStatus = o.order_status === "PAID" ? "PAID" : "";
      //const paymentStatus = o.payment_info;
      //  const totalAmount = o.total_price;
      const discountAmount = o.discount;
      const couponCode = o.cupon_code;
      const couponAmount = o.cupon_discount || 0;

      const givePoint = o.point_gain || 0;
      const usedPoint = o.point_used || 0;
      const usedPointAmount = o.point_discount || 0;

      const soldBy = o.cashier_name || "";
      const township = o.shipping_township || "";
      const state = o.shipping_region || "";
      const address = o.shipping_address || "";

      const shippingAmount = o.shipping_fee_price || 0;
      const totalTax = o.tax;
      const totalAmount = o.grand_total;
      const subTotal = o.price_b4_discount || 0;
      o.order_items.forEach((oi, index) => {
        let productName =
          oi.stock_item && oi.stock_item.product
            ? oi.stock_item.product.name
            : "";
        let productStockItem =
          oi.stock_item?.name || oi.quick_order_item_name || "";
        let stockQty = oi.stock_item ? oi.stock_item.stock : 0;
        let category = (oi.stock_item?.product?.product_categories || [])
          .map((pc) => pc.name)
          .join(",");
        let sku = oi.stock_item?.sku || "";
        let { name: productUnit = "" } = JSON.parse(
          oi.stock_item?.unit || "{}"
        ) as { name?: string };
        let orderQty = oi.count;
        let compareDiscount =
          Number(oi.stock_item?.original_price || 0) -
          Number(oi.stock_item?.price || 0);
        // let price = oi.stock_item?.price || 0;
        let price = Number(oi.price) / Number(oi.count);
        let comparePrice = oi.stock_item?.original_price || 0;
        const perItemDiscount =
          ((oi.price_b4_discount || 0) - (oi.price || 0)) / oi.count;
        const originalPrice = price + perItemDiscount;

        let modifiers = (oi.optional_variations || [])
          .map((ov) => ov.slug)
          .join(",");
        if (index === 0) {
          excelData.push({
            orderNo,
            orderDate,
            category,
            sku,
            productName,
            productStockItem,
            productUnit,
            modifiers,
            comparePrice,
            compareDiscount,
            originalPrice,
            perItemDiscount,
            price,
            orderQty,
            subTotal: "",
            couponCode,
            couponAmount,
            discountAmount,
            usedPoint,
            usedPointAmount,
            tax: totalTax,
            shippingAmount,
            totalAmount,
            givePoint,
            stockQty,
            orderStatus,
            paymentStatus,
            saleChannel,
            soldBy,
            branch,
            customerNote,
            customerName,
            customerPhone,
            township,
            state,
            address,
            payment,
          });
        } else {
          excelData.push({
            orderNo,
            orderDate: "",
            category,
            sku,
            productName,
            productStockItem,
            productUnit,
            modifiers,
            comparePrice,
            compareDiscount: "",
            originalPrice,
            perItemDiscount,
            price,
            orderQty,
            subTotal,
            totalAmount: "",
            couponCode: "",
            couponAmount: "",
            discountAmount: "",
            usedPoint: "",
            usedPointAmount: "",
            tax: "",
            shippingAmount: "",
            givePoint: "",
            stockQty,
            orderStatus: "",
            paymentStatus: "",
            saleChannel: "",
            soldBy: "",
            branch: "",
            customerNote: "",
            customerName: "",
            customerPhone: "",
            township: "",
            state: "",
            address: "",
            payment: "",
          });
        }
      });
    });
    ExportExcel(excelData, fileName);
    setExportLoading(false);
  };
  const columns = [
    {
      title: "OrderNo",
      dataIndex: "order_number",
      key: "order_number",
      filteredValue:
        selectedFilter === "orderNo" && searchText ? [searchText] : null,
      onFilter: (value: string, record: Order) =>
        record.order_number.toLowerCase().includes(value.toLowerCase()),
      ellipsis: true,
      sorter: (a: Order, b: Order) =>
        a.order_number.localeCompare(b.order_number),
    },
    {
      title: "Date",
      dataIndex: "created_at",
      key: "created_at",
      sorter: (a: Order, b: Order) =>
        new Date(a.created_at).getDate() - new Date(b.created_at).getDate(),
      render: (v: number, record: Order) => {
        return format(new Date(record.created_at), "yyyy-MMM-dd HH:mm");
      },
    },
    {
      title: "Customer",
      dataIndex: "merchant_customer",
      key: "merchant_customer",
      filteredValue:
        selectedFilter === "customer" && searchText ? [searchText] : null,
      onFilter: (value: string, record: Order) =>
        (
          record.merchant_customer?.name ||
          record.merchant_customer?.customer?.name ||
          record.merchant_customer?.customer?.contact_number ||
          ""
        )
          .toLowerCase()
          .includes(value.toLowerCase()),
      render: (v: number, record: Order) => {
        const val =
          record.merchant_customer?.name ||
          record.merchant_customer?.customer?.name ||
          record.merchant_customer?.customer?.contact_number ||
          "";
        return val;
      },
      sorter: (a: Order, b: Order) =>
        (a.merchant_customer && a.merchant_customer.customer
          ? a.merchant_customer.customer.name
          : ""
        ).localeCompare(
          b.merchant_customer && b.merchant_customer.customer
            ? b.merchant_customer.customer.name
            : ""
        ),
    },
    {
      title: "Phone",
      dataIndex: "merchant_customer",
      key: "merchant_customer",
      filteredValue:
        selectedFilter === "phone" && searchText ? [searchText] : null,
      onFilter: (value: string, record: Order) =>
        (record.merchant_customer && record.merchant_customer.customer
          ? record.merchant_customer.customer.contact_number
          : ""
        )
          .toLowerCase()
          .includes(value.toLowerCase()),
      render: (v: number, record: Order) => {
        const val =
          record.merchant_customer && record.merchant_customer.customer
            ? record.merchant_customer.customer.contact_number
            : "";
        return val;
      },
      sorter: (a: Order, b: Order) =>
        (a.merchant_customer && a.merchant_customer.customer
          ? a.merchant_customer.customer.contact_number
          : ""
        ).localeCompare(
          b.merchant_customer && b.merchant_customer.customer
            ? b.merchant_customer.customer.contact_number
            : ""
        ),
    },
    // {
    //     title: 'Shipping',
    //     dataIndex: 'shipping_fee_name',
    //     key: 'shipping_fee_name',
    //     filteredValue: (selectedFilter === 'shipping' && searchText) ? [searchText] : null,
    //     onFilter: (value: string, record: Order) => (record.shipping_fee_name || "").toLowerCase().includes(value.toLowerCase()),
    //     render: (v: number, record: Order) => {
    //         return record.shipping_fee_name || "";
    //     },
    //     sorter: (a: Order, b: Order) => (a.shipping_fee_name || "").localeCompare(b.shipping_fee_name || "")
    // },
    {
      title: "Discount",
      dataIndex: "discount",
      key: "discount",
      render: (v: number, record: Order) => {
        return record.discount || "";
      },
      sorter: (a: Order, b: Order) => (a.discount || 0) - (b.discount || 0),
    },
    {
      title: "Give Point",
      dataIndex: "point",
      key: "point",
      render: (v: number, record: Order) => {
        return record.point_gain || "";
      },
      sorter: (a: Order, b: Order) => (a.point_gain || 0) - (b.point_gain || 0),
    },
    {
      title: "Shipping Fee",
      dataIndex: "shipping_fee_price",
      key: "shipping_fee_price",
      render: (v: number, record: Order) => {
        return record.shipping_fee_price || "";
      },
      sorter: (a: Order, b: Order) =>
        (a.shipping_fee_price || 0) - (b.shipping_fee_price || 0),
    },
    // {
    //     title: 'Tax',
    //     dataIndex: 'tax',
    //     key: 'tax',
    //     render: (v: number, record: Order) => {
    //         return record.tax || "";
    //     },
    //     sorter: (a: Order, b: Order) => (a.tax || 0) - (b.tax || 0)
    // },
    {
      title: "Total",
      dataIndex: "grand_total",
      key: "grand_total",
      sorter: (a: Order, b: Order) => a.grand_total - b.grand_total,
      render: (v: number, record: Order) => {
        return record.grand_total;
      },
    },
    {
      title: "Status",
      dataIndex: "order_status",
      key: "order_status",
      filteredValue:
        selectedFilter === "status" && searchText ? [searchText] : null,
      onFilter: (value: string, record: Order) =>
        record.order_status.toLowerCase().includes(value.toLowerCase()),
      sorter: (a: Order, b: Order) =>
        a.order_status.localeCompare(b.order_status),
      render: (v: number, record: Order) => {
        let color = "orange";
        if (record.order_status === "ACCEPT") color = "lime";
        if (record.order_status === "COMPLETE") color = "green";
        if (
          record.order_status === "CUSTOMER_CANCEL" ||
          record.order_status === "MERCHANT_CANCEL"
        )
          color = "red";
        if (record.order_status === "PAID") color = "cyan";
        return (
          <Tag color={color}>
            {record.order_status === "MERCHANT_CANCEL" ||
              record.order_status === "CUSTOMER_CANCEL"
              ? "CANCELLED"
              : record.order_status}
          </Tag>
        );
      },
    },
    {
      title: "Channel",
      dataIndex: "sale_channel",
      key: "sale_channel",
      filteredValue:
        selectedFilter === "chanel" && searchText ? [searchText] : null,
      onFilter: (value: string, record: Order) =>
        record.sale_channel.toLowerCase().includes(value.toLowerCase()),
      sorter: (a: Order, b: Order) =>
        a.sale_channel.localeCompare(b.sale_channel),
      render: (v: number, record: Order) => {
        return record.sale_channel;
      },
    },
    {
      title: "Branch",
      dataIndex: "branch",
      key: "branch",
      //filteredValue: (selectedFilter === 'chanel' && searchText) ? [searchText] : null,
      //onFilter: (value: string, record: Order) => record.sale_channel.toLowerCase().includes(value.toLowerCase()),
      sorter: (a: Order, b: Order) =>
        a.branch?.name.localeCompare(b.branch?.name || ""),
      render: (v: number, record: Order) => {
        return record.branch?.name || " default";
      },
    },
    {
      title: "Action",
      key: "action",
      // align: 'center',
      width: "12%",
      fixed: "right",
      render: (v: number, record: Order) => {
        const available =
          record.order_status === "COMPLETE" && record.merchant_customer;
        const customerAddress = record.shipping_address || undefined;
        return (
          <ButtonGroup style={{ alignItems: "flex-end" }}>
            <PdfExportButton
              loading={false}
              onClick={() => generateInvoice(record, getInvoiceInfo())}
            />
            {available &&
              record.shipment_id &&
              record.shipment?.shipment_agent === "NINJAVAN" &&
              availableDeli(record.shipment.shipment_agent) && (
                <DeliveryInfo
                  customerAddress={customerAddress}
                  merchantId={merchantId || ""}
                  orderId={record.id}
                  orderNo={record.order_number}
                  order={record}
                />
              )}
            {available &&
              record.shipment_id &&
              record.shipment?.shipment_agent === "ARLUPOST" &&
              availableDeli(record.shipment.shipment_agent) && (
                <ArlupostDelivery
                  customerAddress={customerAddress}
                  merchantId={merchantId || ""}
                  orderId={record?.id}
                  orderNo={record.order_number}
                  order={record}
                  shipmentId={record.shipment.shipment_id}
                />
              )}
            {available &&
              record.shipment_id &&
              record.shipment?.shipment_agent === "DEEDELIVERY" &&
              availableDeli(record.shipment.shipment_agent) && (
                <DeeDelivery
                  customerAddress={customerAddress}
                  merchantId={merchantId || ""}
                  orderId={record.id}
                  orderNo={record.order_number}
                  order={record}
                  shipmentId={record.shipment.shipment_id}
                />
              )}
            {available &&
              record.shipment_id &&
              record.shipment?.shipment_agent === "ROYALEXPRESS" &&
              availableDeli(record.shipment.shipment_agent) && (
                <Link to={`https://delivery.pitiplus.app/order/${record.id}`} target="_blank">
                  <Button  icon={<DelvieryIcon />} style={{ border: 'none' }} />
                  </Link>
              )}
            <ButtonWithIcon
              toolTip="Order Detail"
              loading={false}
              icon={EyeIcon}
              onClick={() => navigate(paths.getOrderDetailRoute(record.id))}
              style={{ border: "none" }}
            />
          </ButtonGroup>
        );
      },
    },
  ];
  const expandedRowRender = (row: Order) => {
    const columns = [
      { title: "Name", dataIndex: "name", key: "name" },
      {
        title: "Value",
        key: "value",
        render: (
          v: number,
          record: {
            key: number;
            name: string;
            value: any;
            remark: string;
            path: string | null;
          }
        ) => {
          return availableCRM() && record.path ? (
            <Link to={record.path}>{record.value}</Link>
          ) : (
            record.value
          );
        },
      },
      { title: "Remark", dataIndex: "remark", key: "remark" },
    ];
    let rowData: Array<{
      key: number;
      name: string;
      value: any;
      remark: string;
      path?: string | null;
    }> = [];
    rowData.push({
      key: 1,
      name: "OrderNo",
      value: row.order_number,
      remark: "",
    });
    rowData.push({
      key: 2,
      name: "Date",
      value: format(new Date(row.created_at), "yyyy-MMM-dd HH:mm"),
      remark: "",
    });
    let customer = "",
      customerId: string | null = null;
    if (row.merchant_customer && row.merchant_customer.customer) {
      customer =
        (row.merchant_customer?.name ||
          row.merchant_customer?.customer?.name ||
          row.merchant_customer?.customer?.contact_number ||
          "") +
        "," +
        row.merchant_customer.customer.contact_number;
      customerId = row.merchant_customer.customer?.id;
    }

    rowData.push({
      key: 3,
      name: "Customer",
      value: customer,
      remark: row.customer_note || "",
      path: customerId ? paths.getCustomerDetailRoute(customerId) : null,
    });
    //rowData.push({ key: 4, name: "Notes", value: row.customer_note || "", remark: "" });
    rowData.push({
      key: 5,
      name: "Order Status",
      value: row.order_status,
      remark: "",
    });
    rowData.push({
      key: 6,
      name: "Channel",
      value: row.sale_channel,
      remark: "",
    });
    if ((row.shipping_fee_price || 0) > 0)
      rowData.push({
        key: 7,
        name: "Shipping",
        value:
          (row.shipping_fee_name || "") + "," + (row.shipping_fee_price || ""),
        remark: "",
      });
    rowData.push({
      key: 7,
      name: "Shipping Address",
      value:
        (row.shipping_address || "") +
        "," +
        (row.shipping_township || "") +
        "," +
        (row.shipping_region || ""),
      remark: "",
    });
    if (row.point_used > 0)
      rowData.push({
        key: 77,
        name: "Used Point",
        value: row.point_used || 0,
        remark: formatMoney(row.point_discount, 0),
      });
    if (Number(row.discount) > 0)
      rowData.push({
        key: 78,
        name: "Discount",
        value: formatMoney(row.discount, 0),
        remark: row.discount_info || "",
      });
    if (Number(row.cupon_discount || 0) > 0)
      rowData.push({
        key: 79,
        name: "Coupon Discount",
        value: formatMoney(row.cupon_discount || 0, 0),
        remark: row.cupon_code || "",
      });
    rowData.push({
      key: 8,
      name: "Total Amount",
      value: row.total_price,
      remark: "",
    });
    rowData.push({
      key: 9,
      name: "Total Net Amount",
      value: row.grand_total,
      remark: "",
    });
    let i = 9;
    row.order_items.forEach((item) => {
      const lineItemPrice = Number(item.price) / Number(item.count);
      i += 1;
      let name =
        item.stock_item?.name ||
        item.quick_order_item_name ||
        "" + "(" + lineItemPrice + ")";
      if (item.stock_item && item.stock_item.product) {
        name = `${item.stock_item.product.name},${name}`;
      }
      const value = item.count;

      const remark = `${value * lineItemPrice}`;
      rowData.push({ key: i, name, value, remark });
    });
    const mp = (merchantData || {}) as {
      payPointSystem?: PayPointSystem;
      apiKey?: string;
    };
    return (
      <Card
        extra={
          mp.payPointSystem &&
          mp.payPointSystem.status === "active" && (
            <PayPoint
              merchantId={merchantId!}
              apiKey={mp.apiKey || ""}
              phoneNo={row.merchant_customer?.customer?.contact_number || ""}
              orderId={row.id}
              orderNo={row.order_number}
              totalAmount={row.grand_total}
              payPointSystem={mp.payPointSystem!}
            />
          )
        }
      >
        {" "}
        <Table
          showHeader={false}
          size="small"
          columns={columns}
          dataSource={rowData}
          pagination={false}
        />{" "}
      </Card>
    );
  };
  const selectBefore = (
    <Select
      defaultValue={selectedFilter}
      className="select-before"
      style={{ width: 120 }}
      onChange={(v: Filter) => setSelectedFilter(v)}
    >
      <Option value="orderNo">orderNo</Option>
      <Option value="phone">phone</Option>
      <Option value="chanel">sale channel</Option>
      <Option value="customer">customer</Option>
      <Option value="status">order status</Option>
      {/* <Option value="shipping">shipping</Option> */}
    </Select>
  );
  const selectExcelFormat = (
    <Select
      defaultValue={excelFormat}
      className="select-before"
      style={{ width: 120, marginLeft: 3 }}
      onChange={(v: ExcelFormat) => setExcelFormat(v)}
    >
      <Option value="record">records(E1)</Option>
      <Option value="summary">summary(E2)</Option>
    </Select>
  );
  return (
    <React.Fragment>
      {loading && <LoadingSpinner />}
      {!loading && !error && (
        <>
          <Row>
            <Col flex={4}>
              <Row>
                <Col span={12}>
                  <Typography level={2}> Orders </Typography>
                </Col>
                <Col span={12}>
                  <Search
                    addonBefore={selectBefore}
                    placeholder="search..."
                    allowClear
                    size="default"
                    onSearch={(val: any) => setSearchText(val.toLowerCase())}
                  />
                </Col>
              </Row>
            </Col>
            <Col flex={0}>
              <Row>
                <DateRange
                  startDate={ranges.startDate}
                  endDate={ranges.endDate}
                  onSelect={onSelect}
                />
                {selectExcelFormat}
                <ExportButton
                  loading={exportLoading || loading}
                  onClick={
                    excelFormat === "record" ? onExport : onSummaryExport
                  }
                />
              </Row>
            </Col>
          </Row>
          <Row style={{ marginTop: 5 }}>
            <Table
              columns={columns}
              dataSource={(data.orders as Order[]).map((row, i) => {
                return { ...row, key: i + 1 };
              })}
              expandable={{
                expandedRowRender,
                //rowExpandable: (record: Order) => record.order_status !== 'Not Expandable',
              }}
              pagination={{
                position: ["bottomRight"],
              }}
            />
          </Row>
        </>
      )}
    </React.Fragment>
  );
};

export default withUser(withMerchant(OrderList)) as any;
